import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ItemCard from '@/components/ItemCard2'
import Button from '@material-ui/core/Button';
import { alpha, withStyles } from '@material-ui/core/styles';
import * as JsSearch from "js-search"
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';
import { set } from "lodash";

const csv2json = require('csvtojson');

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: 10,
    width: 70,
    height: 70,
  },
  ListItemParentDiv: {
    display: "flex",
  },
  flexBoxParentDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    "&::after": {
      content: "",
      flex: "auto",
    },
  },
  search: {
    justifyContent: "center",
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  selectedButton: {
    backgroundColor: '#eee', // Adjust the color as needed
  },
})



const SecondPage = props => {
  const { classes } = props
  // Client-side Runtime Data Fetching
  // highlight-start

 

  const [data, setData] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const alwaysEnabled = false
  const isDebug = false
  const poxyUrl = 'https://cors-anywhere.herokuapp.com/'
  const googleSheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQUyutYtUgTKaEBokMMM-6G_Oh3I4LJ4nplwq_9LdztCF6UrLnFuztcCpXeRyv2iPAdtt5VO3DGpwDm/pub?gid=0&single=true&output=csv&headers=0&range=A2:ZZ'
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(googleSheetUrl);
      const csvText = await response.text();
      const jsonData = await csv2json().fromString(csvText);
      const filteredData = jsonData.filter((r) => r.enabled === 'Y');
      setData(filteredData.reverse());
    };

    fetchData();
  }, []);
  // highlight-end

  // get total number of items
  const totalItems = data.length


  var jsSearch = new JsSearch.Search('title');
  jsSearch.addIndex( 'title');
  jsSearch.addIndex( 'description')
  jsSearch.addIndex( 'category')

  jsSearch.addDocuments(data)

  const onChange = (e) => {
    setSearchQuery(e.target.value);
    setSearchResults(jsSearch.search(e.target.value))
  };


  // get a list of all the categories
  const categories = data.map(item => item.category)
  // remove duplicates from the categories list
  const uniqueCategories = [...new Set(categories)]
  // sort the categories list
  uniqueCategories.sort()

  const [selectedCategory, setSelectedCategory] = useState('');

  var jsSearch2 = new JsSearch.Search('code');
  jsSearch2.addIndex( 'category')

  jsSearch2.addDocuments(data)

  // when a category is clicked, filter the results to show only items that have a css class of that category
  const filterResults = (event) => {
    const target = event.target.closest('[data-category]');
    if (!target) return;
  
    const clickedCategory = target.getAttribute('data-category');
  
    // Toggle the selected category
    if (selectedCategory === clickedCategory) {
      setSelectedCategory('');
      setSearchQuery('');
      setSearchResults(jsSearch2.search(''));
    } else {
      setSelectedCategory(clickedCategory);
      setSearchQuery(clickedCategory);
      setSearchResults(jsSearch2.search(clickedCategory));
    }
  };

  const queryResults = searchQuery === "" ? data : searchResults
  //const queryResults = searchQuery === "" ? items : searchResults
  //const fetchResults = data
 

  // display the categories list as html links with total count of each category
  const categoryLinks = uniqueCategories.map((category, index) => {
    const isSelected = selectedCategory === category;
      return (
          <Button variant="outlined" color="primary"
          size="small"
          //endIcon={<StorefrontIcon />}
          classes={{ root: isSelected ? classes.selectedButton : '' }}
          //className={classes.button}
          onClick={filterResults}
          rel="cats"
          key={index}
          component="a"
          data-category={category}
          >
            {category}
            <span className="catcount"> {categories.filter(cat => cat === category).length}</span> 
            {isSelected ? <span className="catclose">ⓧ</span> : ''}
        </Button>
      )
    }
  )

//

classes.flexBoxParentDiv = classes.flexBoxParentDiv + " " + "vidlist"

  return (
    <>
      <SEO title="Imperial Kiss Videos" />
      <div className={classes.root}>
        <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="background.paper">
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={onChange}
            />
          </div>
        </Box>

        <div className="catmenu">
          {categoryLinks}
        </div>
        

        {queryResults.length} Videos <br />&nbsp;<br />

        <div className={classes.flexBoxParentDiv}>
          {queryResults.map((item, index) => (

            <ItemCard key={index} item={item} />

          ))}
        </div>
      </div>

    </>
  )
}

export default withStyles(styles)(SecondPage);
