import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShareButton from '@/components/ShareButton';
import _get from 'lodash.get';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import moment from 'moment';
import tz from 'moment-timezone';

const styles = () => ({

});

function ItemCard(props) {
  const { classes, item } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const playVideo = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function myCopyFunction() {
    // Get the text field
    const codecopy = _get(item, 'code', '');
     // Copy the text inside the text field
    navigator.clipboard.writeText("#"+codecopy);
    // Alert the copied text
    // change only the button that was clicked to say "Copied" for 10 seconds and then change back to "Copy"
    // make sure to get the button that was clicked
    var copybutton = document.activeElement;
    copybutton.innerHTML = "COPIED";
    setTimeout(function(){ copybutton.innerHTML = "COPY"; }, 7000);
  }

  // clicking on description should toggle the .truncate class for the description that was clicked
  function toggleTruncate(e) {
    e.target.classList.toggle('truncate');
  }

  const title = _get(item, 'title', '');
  const  category = _get(item, 'category', '');
  var description = _get(item, 'description', '');
  var vidlength = _get(item, 'length', '');
  var vidurl = "/vid/" + _get(item, 'video_url', '');


  var imgurl = _get(item, 'thumbnail_url', '');

 // if imgurl is empty, then set it to a default image
  if (!imgurl) {
    imgurl = "ikavatar.jpg";
  }


  // if imgurl contains 'http', then it is an external image, so use it as is
  // if imgurl does not contain 'http', then it is an internal image, so prepend the base url
  if (!imgurl.includes('http')) {
    imgurl = "/img/" + imgurl;
  }

  // if length starts with '0:', remove it
  if (vidlength.startsWith('0:')) {
    vidlength = vidlength.slice(2);
  }

  // if vidurl = /vid/, then there is no video, so set vidurl to null
  if (vidurl === "/vid/") {
    vidurl = null;
  }

  // add category class to classes.root
  const categoryClass = category.toLowerCase().replace(' ', '-');
  const rootClass = `${classes.root} ${categoryClass}`;

  // check if the video_url exists and if so, add the play button
  const playButton = vidurl ? <a className="bg-play-btn" onClick={playVideo}></a> : null;

  return (
    <Card className={rootClass}>
      <CardMedia
        className={classes.media}
        image={imgurl}
        title={`${title}`}
        children={playButton}
      />
      <span className="vidlength">{`${vidlength}`}</span>
      <CardContent>
        <Typography variant="h2" color="textSecondary" component="h2">
          {`${title}`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className="truncate description" onClick={toggleTruncate}>
          {`${description}`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className="messageme">
       <strong> <span className="downArrow">⤵</span> 
       Message Me:</strong><br />Video #{`${item.code}`} 
       
       <Button variant="outlined" color="secondary"
          size="small"
          //endIcon={<StorefrontIcon />}
          className={classes.button + " copybutton"} 
          onClick={myCopyFunction}
          target="_blank"
          rel="bsg"
          >
          COPY
        </Button>
       
        </Typography>
        <Button variant="outlined" color="primary"
          size="large"
          //endIcon={<StorefrontIcon />}
          className={classes.button}
          target="_blank"
          rel="bsg"
          href={`https://onlyfans.com/my/chats/chat/145945561/`}>
          GET IT NOW ON <img className="oficon" width="24" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNzUgMjUwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNzUgMjUwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzAwQUVFRjt9Cgkuc3Qxe2ZpbGw6IzAwOENDRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMjUsMEM1NiwwLDAsNTYsMCwxMjVzNTYsMTI1LDEyNSwxMjVzMTI1LTU2LDEyNS0xMjVTMTk0LDAsMTI1LDB6IE0xMjUsMTYzYy0yMSwwLjYtMzguNC0xNi0zOS0zNwoJczE2LTM4LjQsMzctMzljMjEtMC42LDM4LjQsMTYsMzksMzdjMCwwLjMsMCwwLjcsMCwxYzAuNiwyMC40LTE1LjYsMzcuNC0zNiwzOEMxMjUuNywxNjMsMTI1LjMsMTYzLDEyNSwxNjN6Ii8+CjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0yNjUsOTRjMzIsOSw2OSwwLDY5LDBjLTExLDQ3LTQ1LDc3LTk1LDgxYy0xOS44LDQ1LjQtNjQuNSw3NC44LTExNCw3NWwzNy0xMTlDMjAxLDgsMjIwLDAsMzEyLDBoNjMKCUMzNjQsNDYsMzI4LDgyLDI2NSw5NHoiLz4KPC9zdmc+Cg==" />
        </Button>
        <Modal open={isModalOpen} onClose={closeModal} center>
          <video width="100%" controls autoPlay>
            <source src={vidurl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(ItemCard);